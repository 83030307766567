import PropTypes from "prop-types"
import styled from "styled-components"

const Close = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 12px;
    top: 12px;
    content: " ";
    height: ${props => (props.small ? `12px` : `24px`)};
    width: ${props => (props.thin ? `2px` : `5px`)};
    background-color: ${props => props.color || `white`};
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
`

Close.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thin: PropTypes.bool,
  small: PropTypes.bool,
}

export default Close
